<template>
  <div class="page">
    <v-list
      isManySelect
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <v-button
          v-if="fromRouterName == 'iotWarning'"
          text="返回"
          @click="goBack"
        ></v-button>
      </template>
      <template #searchSlot>
        <v-select
          @change="workTypechange"
          @clear="workTypeClear"
          clearable
          :options="workOrderTypeList"
          v-model="searchParam.workTypeName"
          label="工单类型"
        />
        <v-select
          clearable
          :options="eventTypeList"
          v-model="searchParam.incidentName"
          label="事件类型"
        />
        <v-select
          clearable
          :options="workOrderStatus"
          v-model="searchParam.status"
          label="工单状态"
        />
        <v-input
          label="发布人"
          v-model="searchParam.name"
          placeholder="请输入姓名"
        />
        <v-input
          label="电话"
          v-model="searchParam.phone"
          placeholder="请输入电话号码"
        />
        <v-input
          label="工单编号"
          v-model="searchParam.id"
          placeholder="请输入工单编号"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="转派"
          v-if="scope.row.status == 1"
          type="text"
          @click="reassignment(scope.row)"
        />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
        <v-button text="详情" type="text" @click="detail(scope.row)" />
      </template>
      <template #batchSlot="scope">
        <v-button
          text="批量转派"
          @click="batchSend(scope.selectedData)"
        ></v-button>
      </template>
    </v-list>
    <!-- 转派弹框 -->
    <v-dialog title="转派" v-model="dialogFormVisible" @confirm="submit">
      <el-form label-position="right" label-width="100px">
        <el-form-item label="转派人">
          <v-select
            clearable
            :options="otherMenList"
            v-model="temp.receiverName"
          />
        </el-form-item>
        <el-form-item label="转派说明">
          <v-input type="textarea" :rows="4" v-model="temp.redeployExplain" />
        </el-form-item>
      </el-form>
    </v-dialog>
    <!-- 详情弹框 -->
    <v-dialog
      title=""
      v-model="dialogFormVisible_detail"
      width="80%"
      :showConfirmButton="false"
      cancelTxt="关闭"
      oStyle="justify-content: center;"
    >
      <div class="detail_box">
        <p class="title">订单流程</p>
        <div class="step_box">
          <div :class="['item', { now_step: order_detail.reportedTime != '' }]">
            <div class="type_title">下单时间</div>
            <div class="time">{{ order_detail.reportedTime }}</div>
          </div>
          <div :class="['item', { now_step: order_detail.receiveTime != '' }]">
            <div class="type_title">接单时间</div>
            <div class="time">{{ order_detail.receiveTime }}</div>
          </div>
          <div :class="['item', { now_step: order_detail.disposeTime != '' }]">
            <div class="type_title">开始处理</div>
            <div class="time">{{ order_detail.disposeTime }}</div>
          </div>
          <div :class="['item', { now_step: order_detail.finishTime != '' }]">
            <div class="type_title">处理完成</div>
            <div class="time">{{ order_detail.finishTime }}</div>
          </div>
        </div>
        <p class="title">订单信息</p>
        <div class="order_information">
          <p>工单编号:{{ order_detail.id }}</p>
          <p>工单类型:{{ order_detail.workType }}</p>
          <p>事件类型:{{ order_detail.snapshotType }}</p>
          <p>工单状态:{{ workStatusMap(order_detail.status) }}</p>
          <p>事件描述:{{ order_detail.eventDescription }}</p>
          <div
            class="pic_list"
            v-if="
              order_detail.eventPicture && order_detail.eventPicture.length > 0
            "
          >
            <div
              class="img_box"
              v-for="(item, index) in order_detail.eventPicture.split(',')"
              :key="index"
            >
              <img :src="item" alt="" />
            </div>
          </div>
          <p>创建时间:{{ order_detail.createTime }}</p>
          <p>发布人:{{ order_detail.informerName }}</p>
          <p>联系方式:{{ order_detail.informerPhone }}</p>
          <p>事件地点:{{ order_detail.eventPlace }}</p>
        </div>
        <p class="title" v-if="order_detail.status != 1">回复内容</p>
        <div class="message_box" v-if="order_detail.status != 1">
          <v-input
            disabled
            style="width: 40%"
            type="textarea"
            :textAreaRows="5"
            :placeholder="message_placeholder"
            v-model="order_detail.response"
          >
          </v-input>
        </div>
        <p class="title" v-if="order_detail.status == 4">处理图片</p>
        <div class="order_information" v-if="order_detail.status == 4">
          <div
            class="pic_list"
            v-if="order_detail.editPhoto && order_detail.editPhoto.length > 0"
          >
            <div
              class="img_box"
              v-for="(item, index) in order_detail.editPhoto.split(',')"
              :key="index"
            >
              <img :src="item" alt="" />
            </div>
          </div>
        </div>
        <p class="title" v-if="order_detail.status == 4">用户反馈</p>
        <div class="order_information" v-if="order_detail.status == 4">
          <p>满意度:{{ happyStatusMap(order_detail.satisfaction) || "--" }}</p>
          <p>意见建议:{{ order_detail.suggestion || "--" }}</p>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {
  showWorkOrderListUrl,
  workOrderTypeListUrl,
  incidentTypeListUrl,
  redeployWorkOrderUrl,
  workOrderDetailsUrl,
  showReceiverListUrl,
  redeployReceiverListUrl,
  removeWorkOrderUrl,
} from "./api.js";
import { Message, MessageBox } from "element-ui";
import {
  workOrderStatus,
  workOrderStatusMap,
  workOrderStatusOps,
  satisfactionStatus,
  satisfactionStatusMap,
  satisfactionStatusOps,
} from "./map.js";
export default {
  name: "workOrder",
  data() {
    return {
      workOrderStatus,
      satisfactionStatus,
      tableUrl: showWorkOrderListUrl,
      methods: "post",

      dialogFormVisible: false,
      temp: {
        ids: [],
        receiverName: null,
        redeployExplain: null,
      },

      dialogFormVisible_detail: false,
      message_placeholder: "",
      order_detail: {
        reportedTime: "",
        receiveTime: "",
        disposeTime: "",
        finishTime: "",
        id: null,
        workType: null,
        snapshotType: null,
        status: null,
        eventDescription: null,
        eventPicture: null,
        createTime: null,
        informerName: null,
        informerPhone: null,
        eventPlace: null,
        satisfaction: null,
        suggestion: null,
        response: null,
        editPhoto: null,
      },

      searchParam: {
        workTypeName: null,
        incidentName: null,
        id: null,
        name: null,
        phone: null,
      },
      headers: [
        {
          prop: "id",
          label: "工单编号",
        },
        {
          prop: "workTypeName",
          label: "工单类型",
        },
        {
          prop: "incidentTypeName",
          label: "事件类型",
        },
        {
          prop: "eventPlace",
          label: "事件地点",
          formatter: (row, prop) => {
            return row.eventPlace || "-";
          },
        },
        {
          prop: "eventDescription",
          label: "事件描述",
        },
        {
          prop: "informerName",
          label: "发布人",
        },
        {
          prop: "informerPhone",
          label: "联系电话",
          formatter: (row, prop) => {
            return row.informerPhone || "-";
          },
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "receiverName",
          label: "接单人",
        },
        {
          prop: "status",
          label: "工单状态",
          formatter: (row, prop) => {
            return workOrderStatusMap[row.status];
          },
        },
        {
          prop: "satisfaction",
          label: "用户评价",
          formatter: (row, prop) => {
            if (row.satisfaction != 4) {
              return "-";
            } else {
              return satisfactionStatusMap[row.satisfaction];
            }
          },
        },
      ],
      workOrderTypeList: [], //筛选条件工单类型list
      eventTypeList: [], //筛选条件事件类型list
      otherMenList: [], //转派人list
      fromRouterName: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromRouterName = from.name;
    });
  },
  mounted() {
    this.getWorkOrderTypeList();
  },
  methods: {
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          id: item.id,
        };
        this.$axios
          .post(`${removeWorkOrderUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    workStatusMap(value) {
      if (value == 1) {
        return "待受理";
      }
      if (value == 2) {
        return "已接单";
      }
      if (value == 3) {
        return "处理中";
      }
      if (value == 4) {
        return "已完成";
      }
    },
    happyStatusMap(value) {
      if (value == 1) {
        return "非常满意";
      }
      if (value == 2) {
        return "满意";
      }
      if (value == 3) {
        return "不满意";
      }
    },
    workTypechange(value) {
      //工单类型切换
      this.eventTypeList = [];
      this.searchParam.incidentName = null;
      this.workOrderTypeList.forEach((item, index) => {
        if (item.name == value) {
          item.list.forEach((item2, index2) => {
            item2.value = item2.incidentName;
            item2.label = item2.incidentName;
            this.eventTypeList.push(item2);
          });
        }
      });
    },
    workTypeClear() {
      //工单类型清空
      this.searchParam.workTypeName = null;
      this.searchParam.incidentName = null;
      this.eventTypeList = [];
    },
    getWorkOrderTypeList() {
      //获取工单类型列表
      let params = {
        pageSize: 1000000,
        curPage: 1,
      };
      this.$axios
        .get(`${workOrderTypeListUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            console.log(res);
            res.data.records.forEach((item, index) => [
              (item.value = item.name),
              (item.label = item.name),
              this.workOrderTypeList.push(item),
            ]);
          }
        });
    },
    detail(data) {
      //详情按钮
      let params = {
        id: data.id,
      };
      this.$axios
        .get(`${workOrderDetailsUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            // console.log(res);
            this.order_detail.reportedTime = res.data.reportedTime;
            this.order_detail.receiveTime = res.data.receiveTime;
            this.order_detail.disposeTime = res.data.disposeTime;
            this.order_detail.finishTime = res.data.finishTime;
            this.order_detail.id = res.data.id;
            this.order_detail.workType = res.data.workTypeName;
            this.order_detail.snapshotType = res.data.incidentName;
            this.order_detail.status = res.data.status;
            this.order_detail.eventDescription = res.data.eventDescription;
            this.order_detail.eventPicture = res.data.eventPicture;
            this.order_detail.editPhoto = res.data.editPhoto;
            this.order_detail.createTime = res.data.createTime;
            this.order_detail.informerName = res.data.informerName;
            this.order_detail.informerPhone = res.data.informerPhone;
            this.order_detail.eventPlace = res.data.eventPlace;
            this.order_detail.satisfaction = res.data.satisfaction;
            this.order_detail.suggestion = res.data.suggestion;
            if (this.order_detail.status == 2) {
              this.message_placeholder =
                "您的工单已接受，稍后将工作人员为您服务。";
              this.order_detail.response = res.data.receiveResponse;
            } else if (this.order_detail.status == 3) {
              this.message_placeholder = "您的工单正在处理。";
              this.order_detail.response = res.data.disposeResponse;
            } else if (this.order_detail.status == 4) {
              this.message_placeholder = "服务已完毕，请为本次服务评价。";
              this.order_detail.response = res.data.finishResponse;
            }
          }
        });
      this.dialogFormVisible_detail = true;
    },
    reassignment(data) {
      //转派按钮(获取转派人list)
      console.log(data);
      this.temp.ids = data.id;
      let userIdList = []; //用户userId数组
      let servingCellList = []; //小区id数组
      userIdList.push(data.receiverId);
      servingCellList.push(data.servingCell);
      console.log(userIdList);
      console.log(servingCellList);

      let params = {
        tenantId: data.tenantId,
      };
      this.$axios
        .post(`${redeployReceiverListUrl}?tenantId=${data.tenantId}`)
        .then((res) => {
          if (res.code === 200) {
            res.data.forEach((item, index) => [
              (item.value = item.id),
              (item.label = item.name),
            ]);
            this.otherMenList = res.data;
            this.dialogFormVisible = true;
          }
        });
    },
    submit() {
      if (!this.temp.receiverName || this.temp.receiverName == "") {
        this.$message.error("请选择转派人！");
        return;
      }
      if (!this.temp.redeployExplain || this.temp.redeployExplain == "") {
        this.$message.error("转派说明不能为空！");
        return;
      }
      let params = {
        ids: this.temp.ids,
        receiverId: this.temp.receiverName,
        redeployExplain: this.temp.redeployExplain,
      };
      this.$axios
        .post(`${redeployWorkOrderUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code === 200) {
            Message.success("操作成功！");
            this.dialogFormVisible = false;
            this.temp.receiverName = null;
            this.temp.redeployExplain = null;
            this.$refs.list.search();
          }
        });
    },
    isSameArray(array) {
      //是否同一小区
      if (Array.isArray(array)) {
        return new Set(array).size === 1;
      }
      return false;
    },
    batchSend(list) {
      //批量转派按钮
      this.temp.ids = [];

      const { data, ids } = list;
      this.temp.ids = ids;
      let itemList = [];
      itemList = data;
      console.log(list, "9999");
      console.log(this.temp.ids);
      console.log(list.selectedData);

      let userIdList = []; //用户userId数组
      let servingCellList = []; //小区id数组
      let statusList = [];
      let tenantIdList = [];
      itemList.forEach((item) => {
        userIdList.push(item.receiverId);
        servingCellList.push(item.servingCell);
        statusList.push(item.status);
        tenantIdList.push(item.tenantId);
      });
      console.log(userIdList);
      console.log(servingCellList);
      console.log(statusList);
      if (
        statusList.includes(2) == true ||
        statusList.includes(3) == true ||
        statusList.includes(4) == true
      ) {
        this.$message.error("请选择待受理的工单！");
        return;
      }
      if (userIdList.length == 0) {
        this.$message.error("请选择批量转派工单！");
        return;
      }
      if (this.isSameArray(servingCellList) == false) {
        this.$message.error("请选择相同小区！");
        return;
      }
      this.dialogFormVisible = true;
      let params = {
        tenantId: tenantIdList[0],
      };
      this.$axios
        .post(`${redeployReceiverListUrl}?tenantId=${tenantIdList[0]}`)
        .then((res) => {
          if (res.code === 200) {
            res.data.forEach((item, index) => [
              (item.value = item.id),
              (item.label = item.name),
            ]);
            this.otherMenList = res.data;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  height: 100%;

  .detail_box {
    width: 100%;

    .message_box {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      ::v-deep .el-textarea {
        width: 100% !important;
      }
    }

    .order_information {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;

      p {
        margin-bottom: 5px;
      }

      p:nth-last-child(1) {
        margin-bottom: 0px;
      }

      .pic_list {
        width: 100%;
        height: 120px;
        margin-bottom: 5px;
        display: flex;

        .img_box {
          width: 120px;
          height: 100%;
          margin-right: 20px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .step_box {
      width: 100%;
      padding: 20px;
      margin-bottom: 20px;
      box-sizing: border-box;
      display: flex;

      .item {
        flex: 1;
        text-align: center;
        border-bottom: 10px solid #f2f2f2;
      }

      .now_step {
        border-bottom: 10px solid #aaaaaa;
      }
    }

    .title {
      font-weight: 600;
      color: #000;
      font-size: 18px;
    }
  }
}
</style>
